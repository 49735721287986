.edit-item {
  background-color: #ddd;
  padding: 20px;
  position: relative;
  width: 100%;
  overflow: scroll;
}

.edit-item > div {
  margin-bottom: 20px;
}

.img-url {
  width: 100%;
}

.cat-desc {
  width: 100%;
}

.edit-item h2 {
  color: #000;
  font-size: 1.5em;
  text-transform: inherit;
  margin-bottom: 20px;
}

.save-cancel-btn-group {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.save-cancel-btn-group button {
  margin-bottom: 15px;
  width: 49%;
}

.edit-modal {
  background-color: #ddd;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding-bottom: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}

.displayImage {
  width: 100%;
  height: auto;
}

.imageArea {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.uploadButtonArea {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.uploadButtonArea input {
  width: 60%;
}

.uploadButtonAreaLabel {
  font-size: 13px;
  margin-bottom: 5px;
  color: rgba(0, 0, 0, 0.54);
}

.tab {
  color: #4b6bfb;
  background-color: #fefefe;
  margin-left: 0 !important;
  margin-right: 10px;
}

.tab-active {
  background-color: #777 !important;
}

@media only screen and (min-width: 768px) {
  .displayImage {
    width: 300px;
    height: auto;
  }

  .edit-item > div {
    /* margin-right: 20px; */
    margin-bottom: 10px;
  }

  .save-cancel-btn-group {
    display: flex;
    flex-direction: row;
  }

  .save-cancel-btn-group button {
    margin-right: 20px;
    min-width: 100px;
    width: auto;
  }
}
