*,
*:before,
*:after {
  box-sizing: border-box;
}

:root {
  --loading-bg-color: rgba(181, 122, 233, 0.494);
  --loading--before-bg-color: rgb(196, 153, 233);
}

html,
body,
main,
#root {

  height: 100%;
  width: auto;
  padding: 0 !important;
  margin: 0 !important;
  background-color: #eee;
  overflow-y: scroll;
}

body {
  height: 100%;
}

.MuiInputBase-input,
label,
input,
.MuiButton-label {
  font-family: "Avenir Next" !important;
}

.MuiButton-containedPrimary {
  background-color: #ed6a5a !important;
}

.MuiButton-containedSecondary {
  background-color: #fff !important;
  color: #555 !important;
}

.MuiButton-contained.utility {
  background-color: #555 !important;
  color: #fff !important;
  font-size: 12px;
}

.App {
  text-align: left;
  background-color: #eee;
  height: 100%;
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
}

.global-padding-container {
  padding: 20px 5vw;
  word-break: break-all;
  width: auto;
}

.header-background {
  background-color: #ed6a5a;
  color: #fff;
}

.register-yes-no {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.cat-background {
  background-color: #d26355;
  color: #fff;
}

.cat-background small {
  font-size: 1em;
  display: inline-block;
  margin-top: 10px;
  word-break: keep-all;
  white-space: pre-wrap;
}

.fixed {
  position: fixed;
}

.scroll-hidden {
  overflow-y: hidden !important;
}

.contact-text {
  font-size: 1.2em;
  line-height: 1.5em;
}

.loading {
  height: 100%;
  margin: 20px 5vw;
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


.loadingCard {
  width: 100%;
  height: auto;
  background-color: #fff;
  border-bottom: 5px solid #eee;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  margin-bottom: 20px;
}

.loading90 {
  width: 95%;
  border-radius: 20px;
  height: 15px;
  background-color: var(--loading-bg-color);
  margin-bottom: 15px;
  position: relative;
}

.loading60 {
  width: 65%;
  border-radius: 20px;
  height: 15px;
  background-color: var(--loading-bg-color);
  margin-bottom: 15px;
  position: relative;
}

.loading40 {
  width: 45%;
  border-radius: 20px;
  height: 15px;
  background-color: var(--loading-bg-color);
  position: relative;
}

.loading40::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  border-radius: 20px;
  inset: 0;
  background-color: var(--loading--before-bg-color);
  animation: slidein 2s ease-out infinite ;
}

.loading60::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  border-radius: 20px;
  inset: 0;
  background-color: var(--loading--before-bg-color);
  animation:slidein 2s ease-out infinite;
}


.loading90::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  border-radius: 20px;
  inset: 0;
  background-color: var(--loading--before-bg-color);
  animation: slidein 2s ease-out infinite;
}


@keyframes slidein {
  0% {
    width: 0%;
    left: 0%;
  }
  
  50% {
    width: 100%;
    left: 0%;

  }

  100% {
    width: 0%;
    left: 100%;

  }
}



.sectionTitle {
  font-size: 2em;
  word-break: keep-all;
}

.text-center-mobile {
  text-align: center;
}

h1 {
  margin: 0px;
  font-weight: 700;
}

h2 {
  color: #fff;
  font-size: 1.2em;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0px;
}

.line {
  background-color: #bbb;
  width: 100%;
  height: 1px;
  margin: 25px 0;
}

.add-item-link-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: blue;
  cursor: pointer;
}



.add-item-link-group > svg {
  margin-right: 10px;
}

.icon-buttons-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #ccc;
}

.icon-buttons-group button {
  color: #005f61;
}

.MuiTextField-root,
.MuiInputBase-input {
  width: 100%;
}

.MuiFilledInput-root {
  background-color: rgba(255, 255, 255, 1) !important;
}

.category-edit {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #32458F;
  color: #fff;
  margin-bottom: 20px;
  max-width: 400px;
  border-radius: 10px;
}

.category-edit svg {
  color: #fff;
}

.edit-category-name {
  padding: 15px;
  color: #fff;
}

.edit-category-name h2 {
  color: #fff;
  font-size: 1.5em;
  font-weight: 500;
  text-transform: none;
  margin: 5px 0 10px 0;
}

.parent-container {
  position: relative;
  background-color: #eee;
}

.red-container {
  background-color: #ed6a5a;
  color: #fff;
}

.text-center-align {
  text-align: center;
}

.full-height {
  height: 100vh;
}

.login-container {
  padding: 30px;
  background-color: #d26355;
  color: #fff;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  max-width: 400px;
}

.edit-error-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  font-size: 1.5em;
}

.logo {
  background-color: #fff;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.logo svg {
  color: #ed6a5a;
  font-size: 2.5em;
}

.edit-error-container svg {
  font-size: 2.5em;
}

.edit-page-card {
  background-color: #fff;
  border-radius: 10px;
  max-width: 400px;
}

.edit-page-card .item-card {
  cursor: initial;
}

.save-cancel-edit-page {
  background-color: #333;
  padding: 20px;
  bottom: 10px;
  position: fixed;
  width: 50%;
  border-radius: 10px;
  z-index: 100;
  -webkit-box-shadow: -4px -7px 25px -15px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -4px -7px 25px -15px rgba(0, 0, 0, 0.75);
  box-shadow: -4px -7px 25px -15px rgba(0, 0, 0, 0.75);
}

.HeroArea {
  display: flex;
  flex-direction: column-reverse;
  background-color: #ed6a5a;
  color: #f2f2f2;
  padding-top: 5vw !important;
  padding-bottom: 5vw !important;
  text-align: center;
}

.HeroArea > div {
  width: 100%;
  text-align: left;
  min-height: 100%;
}

.HeroText {
  font-size: 1.9em;
  line-height: 1.2em;
  margin: 20px 0;
  word-break: keep-all;
  text-align: center;
  width: 100%;
}

.HeroDesc {
  margin-bottom: 30px;
  font-size: 1em;
  word-break: keep-all;
  width: 100%;
  text-align: center;
}

.HeroButtons button {
  width: 100%;
  margin-bottom: 20px;
}

.body-modal-open {
  overflow: hidden;
}

.benefits {
  color: #fff;
  background-color: #222;
  height: auto;
  padding-top: 10vw !important;
  padding-bottom: 10vw !important;
}

.how-it-works {
  color: #222;
  background-color: #fff;
  height: auto;
  padding-top: 10vw !important;
  padding-bottom: 10vw !important;
}

.hiw-group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  text-align: center;
}

.hiw-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1%;
}

.circle {
  width: 250px;
  height: 250px;
  border-radius: 100px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.circle img {
  width: 90%;
  height: auto;
}

.hiw-step h3 {
  width: 60%;
  margin-bottom: 0;
  word-break: keep-all;
}

/* Feature */

.features-container { 
  padding-top: 10vw;
  padding-left: 20px;
  padding-right: 20px;
}

.features-image {
  width: 100%;
  display: block;
  
}



.features-1 {

  background-color: #DCEB2D;
}



.features-2 {
  background-color: #222;
  color: #fff;
  padding-right: 0px !important;
}

.features-3 {

  background-color: #4470E2;
  color: #fff;
}

/* Benefits Section */

.benefits {
  word-break: keep-all;
}

.benefits-section-image img {
  width: 40%;
  text-align: center;
  margin: 20px 0;
  padding: 0;
}

.benefits-section {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 30px;
}

.col-reverse {
  flex-direction: column;
}

/* Footer */

.footer {
  color: #000;
  background-color: #f2f2f2;
  height: auto;
  padding-top: 10vw !important;
  padding-bottom: 10vw !important;
}

.footer-social .icon {
  font-size: 2.5em;
}

.footer-social {
  margin-bottom: 10px;
}

.footerLogo .logoimg {
  width: 35px;
  height: 35px;
  background-image: url("img/logos/header-alt.png");
  background-size: 100%;
  background-repeat: no-repeat;
  margin-right: 10px;
}

.footerLogo {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #ed6a5a;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -1.5px;
  margin-bottom: 30px;
  width: 100%;
  justify-content: center;
}

/* Clients */
.clients {
  width: 100%;
  background-color: #fff;
  padding: 5vw !important;
}


.carousel-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow-x: scroll;
  min-height: 200px;
  align-items: stretch;
}

/* Business Details Edit Page */

.detailsEditPageSec > div {
  width: 100% !important;
  margin-bottom: 10px;
}

/* Pricing */

.pricing {
  width: 100%;
  background-color: #222;
  color: #fff;
  padding-bottom: 10px;
}

.pricing-table {
  width: 90%;
  margin: 30px 5%;
}

.pricing-row {
  display: flex;
  flex-direction: row;
  max-width: 768px;
  margin-bottom: 10px;
  border-radius: 20px;
}

.pricing-cell {
  background-color: #fff;
  
  color: #222;
  width: 70%;
  padding: 15px;
  text-align: left !important;
  align-items: flex-start;
  border-radius: 5px 0 0 5px;
}

.pricing-cell-left-border {
  border-left: 2px solid #eee;
  width: 30%;
  border-radius: 0 5px 5px 0;
  font-size: 1.4em;
}

.pricing-cell-left-border div {
  margin-top: -10px;
}

.pricing-cell small {
  color: #777;
  font-size: 14px;
}

.pricing-cell p {
  margin-top: 5px;
  line-height: 20px;
}

.pricing-cell h3 {
  margin: 0;
  color: #ed6a5a;
}

@media only screen and (min-width: 769px) {

  /* Nav */
  .drawer-side li { padding: 5px 0; }
  
  .drawer-side li a.active {
    background-color: #32458F !important;
    color: #E8F838 !important;
  }

  
  .text-center-mobile {
    text-align: left;
  }
  .sectionTitle {
    font-size: 3em;
  }

  .parent-container {
    width: 100%;
  }

  .global-padding-container {
    padding: 20px 3vw;
    width: 100%;
    height: 100% !important;
  }

  .itemsDisplay {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .itemsDisplay .edit-page-card {
    margin-right: 20px;
  }

  .edit-item > div {
    width: 100%;
  }

  .HeroButtons button {
    margin-right: 20px;
    width: auto;
  }

  /* .save-cancel-btn-group {
    position: relative;
  } */

  .HeroText {
    font-size: 2.8em;
    line-height: 1.2em;
    text-align: left;
    width: 80%;
  }

  .HeroDesc {
    text-align: left;
    width: 80%;
    font-size: 1.5em;
  }

  .MuiTextField-root,
  .MuiInputBase-input {
    width: auto;
  }

  .HeroArea {
    flex-direction: row;
    text-align: left;
    align-items: center;
    padding-top: 20px;
  }

  .HeroArea > div {
    width: 49%;
    text-align: left;
    margin-right: 20px;
  }

  .icon-buttons-group {
    width: auto;
  }

  .how-it-works {
    padding-top: 5vw !important;
    padding-bottom: 5vw !important;
  }

  .hiw-group {
    flex-direction: row;
  }

  .hiw-step {
    width: 32%;
  }

  /* Features */
  .features-container {
    display: flex;
    flex-direction: row;
    padding: 20px 5vw 0 5vw;
    align-items: center;
    justify-content: space-between;
    text-align: left;
  }
  
  .features-container-copy {
    font-size: 1.5em;
    width: 90% !important;
  }
  
  .features-container > div {
    width: 50%;
  } 

  .features-container img {
    display: block;
  }  

  /* Benefits Section */

  .benefits-section {
    width: 100%;
    height: auto;
    display: block;
    margin: 50px 0 30px 0;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .benefits-section-image {
    /* background-color: #fff; */
    width: 50%;
    height: auto;
    display: block;
    text-align: center;
  }

  .benefits-section-image img {
    max-width: 60%;
    max-height: 100%;
  }

  .benefits-section-copy {
    width: 50%;
    margin-top: 10px;
    text-align: center;
  }

  .benefits-section-copy p {
    font-size: 1.3em;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-top: 5vw !important;
    padding-bottom: 5vw !important;
  }

  .footerLeft {
    text-align: left;
    width: 50%;
    height: 100%;
  }

  .footerRight {
    text-align: right;
    width: 50%;
    height: 100%;
  }

  .footerLogo {
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  .footerLogo .logoimg {
    width: 50px;
    height: 50px;
  }

  /* Clients */
  .carousel-container {
    justify-content: flex-start;
  }

  /* Edit Menu */
  .add-item-link-group {
    justify-content: flex-start;
  }

  /* Pricing */
  .pricing-cell-left-border {
    font-size: 2em;
  }
}


/* Animation */
@keyframes MoveUpDown {
  0%, 100% {
    bottom: -100px;
    opacity: 0;
  }
  10% {
    bottom: 50px;
    opacity: 1;
  }
  90% {
    bottom: 50px;
    opacity: 1;
  }

}

.alertupdown {
  animation: MoveUpDown 3s ease-in-out 0s 1;
  opacity: 0;
}

.customerAlertCard {

  background-color: #f8f8f8;
  border-bottom: 1px solid #ddd;
  border-radius: 10px;
  margin: 10px 0px 20px 0;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}


