.modalcustom {
  z-index: 100;
  width: 90%;
  height: 90%;
  background-color: #fff;
  border-radius: 10px;
  padding: 0 30px;
  overflow: scroll;
  position: relative;
 
}

.lightbox {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 100;
  overflow: scroll;
}

.modalh2 {
  font-size: 1.5em;
  color: #000;
  text-transform: none;
  margin-bottom: 10px;
}

.modalCancel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.modalLink {
  margin: 15px 0;
  background-color: #eee;
  padding: 10px;
  border-radius: 5px;
  color: #641ae6;
  
}

.modalLink:hover {
  background-color: blue;
  cursor: pointer;
}

.modalLink:hover a {
  color: #fff;
  text-decoration: none;
}

.modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #222;
  
  width: 100%;
  padding: 10px 0 ;
  background-color: #fff;
  top: 0;
  left: 0;
  
}

.modal-header

.modal-section-parent{
  padding: 20px;
  margin-top: 20px;
  background-color: #eee;
}

.modal-section {

  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.modal-section > div {

  width: 46%;
}

.modal-table {
  background-color: #fff;
  padding: 10%;
  border-radius: 5px;
} 

.modal-table > div:nth-child(2) {
  text-align: right;
}

.modal-section img {
  max-width: 100%;
  display: block;
} 

@media only screen and (min-width: 768px) {
  .modal {
    z-index: 100;
    width: 70%;
    height: auto;
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
  }
}
