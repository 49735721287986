.item-details {
  background-color: #fff;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
}

.details-img {
  width: 100%;
  height: 250px;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  color: #fff;
}

.close-btn-dark {
  background-color: #ed6a5a;
  width: 100%;
  height: 80px;
  position: relative;
  padding: 0 0px;
}

.close-btn {
  /* background-color: rgba(0, 0, 0, 0.4) !important; */
  position: absolute;
  padding: 20px 10px 10px 10px !important;
  margin: 0px 0 0 10px !important;
  /* border-radius: 0 0 5px 5px !important; */
  line-height: 0;
}

.close-btn svg {
  color: #fff;
  font-size: 30px;
  -webkit-box-shadow: 2px 2px 61px -11px rgba(0, 0, 0, 0.87);
  -moz-box-shadow: 2px 2px 61px -11px rgba(0, 0, 0, 0.87);
  box-shadow: 2px 2px 61px -11px rgba(0, 0, 0, 0.87);
}

.text-content {
  margin: 20px;
}

.text-content {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.text-content h1 {
  font-size: 1.6em;
  font-weight: 400;
  margin-bottom: 20px;
}

.desc {
  color: #777;
  margin-bottom: 20px;
  white-space: pre-wrap;
}

.price {
  font-size: 1.5em;
  white-space: pre-wrap;
}

@media only screen and (min-width: 768px) {
  .details-img {
    background-position: left;
  }
}
