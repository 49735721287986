.item-card {
  background-color: #fff;
  text-align: left;
  width: 100%;
  margin: 20px 0;
  padding: 15px;
  max-width: 400px;
  height: max-content;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.image {
  width: 30%;
  height: auto;
}

.image img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.item-card-content {
  width: 65%;
  max-width: 100%;
  margin-right: 20px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.item-card-content-left {
  width: 100%;
  display: block;
}

.item-card-content-right {
  font-size: 1em;
  font-weight: 500;
  color: #555;
  white-space: pre-wrap;
  margin-top: 10px;
}

.item-title {
  font-size: 1.3em;
  width: inherit;
  word-break: keep-all;
  white-space: pre-wrap;
}

.item-desc {
  color: #999;
  font-size: 0.9em;
  margin-top: 5px;
  line-height: 18px;
  max-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: -webkit-box;
  word-break: keep-all;
  white-space: pre-wrap;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* number of lines to show */
}
